import Shuffle from 'shufflejs';

export class SortItems {
    constructor() {
        
    }

    init() {
        var self = this;
        var element = document.querySelector('.sortitems');

        const shuffleInstance = new Shuffle(element, {
            itemSelector: '.sort-item',
            speed: 800,
        });
        shuffleInstance.filter("all");

        Array.from(document.querySelectorAll('.filterbtn')).forEach(function (btn) {
            btn.addEventListener('click', function () {
                let attr = this.getAttribute('data-sortby');
                console.log(attr);
                //let attrArray = attr.split(','); //self.makeArray(attr);
                if (attr == "all") {
                    shuffleInstance.filter("all");
                } else {
                    shuffleInstance.filter(attr);
                }
                /*Array.from(document.querySelectorAll('.filteroptions .filterbtn')).forEach(function (btn1) {
                    btn1.parentNode.classList.remove('selected');
                    btn1.parentNode.classList.add('invisible');
                });
                this.parentNode.classList.add('selected');
                this.parentNode.classList.remove('invisible');*/
                //gsap.to(window, { duration: 0.8, scrollTo: 0, ease: "power3.out" });
            });
        });
    }
}