//import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { Slider } from "./js/slider.js";
import { Form } from "./js/form.js";
import { Accordion } from "./js/accordion.js";
import { SortItems } from "./js/sortitems.js";

const breakpointSmall = 500;
const breakpointMedium = 690;
const breakpointLargeMedium = 1024;
const breakpointLarge = 1199;
const breakpointXLarge = 1450;

document.addEventListener("DOMContentLoaded", function (event) {
    //const basefunctions = new BaseFunctions(breakpointMedium, breakpointLarge, breakpointXLarge);
    //basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init(breakpointMedium, breakpointLarge, breakpointXLarge);

    const slider = new Slider();
    slider.init();

    const form = new Form();
    form.init();

    const accordion = new Accordion();
    accordion.init();
    if (document.querySelectorAll('.filteroptions').length > 0) {
        const sortitems = new SortItems();
        sortitems.init();
    }
});