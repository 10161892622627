import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';


export class Slider {
    constructor() {
        this.speed = 3000;
        this.fadetime = 800;
    }

    init() {
        const self = this;


        if (document.querySelectorAll('.swiper-container').length > 0) {
            Array.from(document.querySelectorAll('.swiper-container')).forEach(function (s) {
                var slideshow = new Swiper(s, {
                    modules: [Autoplay, Navigation],
                    slidesPerView: 1,
                    loop: true,
                    speed: self.fadetime,
                    grabCursor: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      },
                    autoplay: {
                        delay: self.speed,
                        disableOnInteraction: true
                    },
                });
            });
        }
    }
}