export class Form {
    constructor() {
        this.checked = false;
    }

    init() {
        const self = this;
        if (document.querySelectorAll('.wpcf7 .check-box-container').length > 0) {
            Array.from(document.querySelectorAll('.wpcf7')).forEach(function (form) {
                form.querySelector('.check-box-text').addEventListener('click', function (evt) {
                    if(!self.checked) {
                        self.checked = true;
                        form.querySelector('.check-box span').classList.add('show');
                        form.querySelector('.h-cb').setAttribute('checked', 'checked');
                        form.querySelector('.wpcf7-submit').removeAttribute('disabled');
                    } else {
                        self.checked = false;
                        form.querySelector('.check-box span').classList.remove('show');
                        form.querySelector('.h-cb').removeAttribute('checked');
                    }
                });
                form.querySelector('.check-box').addEventListener('click', function (evt) {
                    if(!self.checked) {
                        self.checked = true;
                        form.querySelector('.check-box span').classList.add('show');
                        form.querySelector('.h-cb').setAttribute('checked', 'checked');
                        form.querySelector('.wpcf7-submit').removeAttribute('disabled');
                    } else {
                        self.checked = false;
                        form.querySelector('.check-box span').classList.remove('show');
                        form.querySelector('.h-cb').removeAttribute('checked');
                    }
                });
            });
        }
    }
}