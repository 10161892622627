export class Accordion {
    constructor() {
        this.minH = 0;
    }

    init() {

        const self = this;
        let maxH = 0;


        if (document.querySelectorAll('.faq-container').length > 0) {
            window.addEventListener('resize', this.getQuHeight.bind(this));
            this.getQuHeight();

            Array.from(document.querySelectorAll('.faq-container')).forEach(function (acc) {
                acc.querySelectorAll('.answer')[0].classList.add('show');
                Array.from(acc.querySelectorAll('.question')).forEach(function (qu) {
                    qu.addEventListener('click', function (evt) {
                        Array.from(acc.querySelectorAll('.question')).forEach(function (qu1) {
                            qu1.classList.remove('text-blue');
                        });
                        this.classList.add('text-blue');

                        Array.from(acc.querySelectorAll('.answer')).forEach(function (answer) {
                            if (answer.getAttribute('data-num') == qu.getAttribute('data-num')) {
                                answer.classList.add('show');
                                maxH = answer.getBoundingClientRect().height;
                                if (acc.querySelectorAll('.faq-wrapper').length > 0) {
                                    if (self.minH + 140 < maxH) {
                                        acc.querySelector('.faq-wrapper').style.height = maxH + 'px';
                                    } else {
                                        acc.querySelector('.faq-wrapper').style.height = self.minH + 140 + 'px';
                                    }
                                }
                            } else {
                                answer.classList.remove('show');
                            }
                            if (acc.querySelectorAll('.answers-m').length > 0) {
                                acc.querySelector('.answers-m').style.height = maxH + 'px';
                                acc.querySelector('.answers-m').classList.add('show');
                            }
                        });
                    });
                });
            });
        }
    }
    getQuHeight() {
        const self = this;
        this.minH = 0;
        Array.from(document.querySelectorAll('.desktop-content-wrapper.faq-container')).forEach(function (acc) {
            for (var i = 0; i < acc.querySelectorAll('.question').length; i++) {
                self.minH += acc.querySelectorAll('.question')[i].getBoundingClientRect().height;
            }
        });
    }
}